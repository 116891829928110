import * as THREE from "three";
let y = 5;
let y1 = 48;

function getVector3(v3) {
    let d = [];
    let sum = 50;
    const addData = (item) => {
        if (d.length == 0)
            d.push(item);
        else {
            let lastItem = d[d.length - 1];
            let deptx = (item.x - lastItem.x) / sum;
            let depty = (item.y - lastItem.y) / sum;
            let deptz = (item.z - lastItem.z) / sum;
            for (let i = 1; i < sum; i++) {
                d.push(new THREE.Vector3(
                    lastItem.x + deptx * i, lastItem.y + depty * i, lastItem.z + deptz * i
                ));
            }
            d.push(item);
        }
    };
    v3.forEach((item) => {
        addData(item);
    })
    let route = new THREE.CatmullRomCurve3(d, false);
    return route;
}


let route1 = [
    new THREE.Vector3(
        96, y, 420
    ),
    new THREE.Vector3(
        96, y, 323
    ),
    new THREE.Vector3(
        98, y, 323
    ),

    new THREE.Vector3(
        310, y, 323
    ),
];
let route2 = [
    new THREE.Vector3(
        310, y, 323
    ),
    new THREE.Vector3(
        332, y, 323
    ),

    new THREE.Vector3(
        334, y, 323
    ),
    new THREE.Vector3(
        334, y, 182
    ),
    new THREE.Vector3(
        334, y1, 182
    ),
    new THREE.Vector3(
        36, y1, 20
    ),
    new THREE.Vector3(
        36, y, 30
    ),
    new THREE.Vector3(
        36, y, 100
    ),
];
let route3 = [
    new THREE.Vector3(
        36, y, 100
    ),
    new THREE.Vector3(
        36, y, 318
    ),

    new THREE.Vector3(
        36, y, 320
    ),
    new THREE.Vector3(
        108, y, 320
    ),
    new THREE.Vector3(
        108, y, 220
    ),
];
let gdy = 1;
let route4 = [
    new THREE.Vector3(
        96, gdy, 420
    ),
    new THREE.Vector3(
        96, gdy, 405
    ),
    new THREE.Vector3(
        218, gdy, 405
    ),
    new THREE.Vector3(
        218, gdy, 360
    ),
];
let route5 = [
    new THREE.Vector3(
        340, gdy, 323
    ),
    new THREE.Vector3(
        330, gdy, 323
    ),
    new THREE.Vector3(
        330, gdy, 405
    ),
    new THREE.Vector3(
        220, gdy, 405
    ),
    new THREE.Vector3(
        220, gdy, 360
    ),
];
let route6 = [
    new THREE.Vector3(
        212, gdy, 340
    ),
    new THREE.Vector3(
        212, gdy, 324
    ),
    new THREE.Vector3(
        40, gdy, 324
    ),
    new THREE.Vector3(
        40, gdy, 20
    ),
];
export let akbutton = [
    {
        name: '总览',
        position: [-33.913844660166816, 12.012800216674805, -383.4020480867691],
        controls: [108.21390562685808, -129.51278441906786, 67.9566070128961],
        cameraPosition: [433.456706289105, 219.68312417497387,540.5150079031882],
        type: "camera",

    },
    {
        name: '厂区',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route1),
        type: "line",
        speed: 0.0005
    },
    {
        name: '绿化喷滴灌',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route2),
        type: "line",
        speed: 0.001
    },
    {
        name: '一、二期',
        position: [290.68172190553724, 6.095502289736345, -60.573421562674056],
        cameraPosition: [451, 109, -94],
        route: getVector3(route3),
        type: "line",
        speed: 0.001
    },
]

export let tube = [
    {
        name: '管道总表到水泵房1',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route4),
        type: "tube",
        speed: 0.002
    },
    {
        name: '管道总表到水泵房2',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route5),
        type: "tube",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        speed: 0.002
    },
]
export let textSp = [
    {
        name: 'A栋',
        position: [111.71235587654394, 13.911935671301023, 114.76128190961768],
        type: "Sprite",
    },
    {
        name: 'P栋',
        position: [248.29313710850641, 45.91567892535305, 124.72028873710038],
        type: "Sprite",
    },
    {
        name: 'N栋',
        position: [247.29079473112367, 45.826575373994714, 234.18201877680224],
        type: "Sprite",
    },
    {
        name: 'CUB2',
        position: [112.02164845394536, 23.65347939162899, 195.30469986251188],
        type: "Sprite",
    },
    {
        name: 'CUB3',
        position: [111.89804619575085, 23.624180873129035, 272.9379017353631],
        type: "Sprite",
    },
    // {
    //     name: '水泵房',
    //     position: [212.8776905092388, 8.30250072479248, 335.2772163263718],
    //     type: "Sprite",
    // },
]
let py = -0.5;
export let pengquan = [
    [54, py, 215]
    ,[54, py, 200]
    ,[54,py, 145]
    ,[54, py, 130]
    ,[36, py, 40]
]

export default [akbutton, tube, textSp,pengquan]
