<template>
  <div class="main" style="overflow-x:hidden">
    <template>
      <div id="app">
        <router-view />
      </div>
    </template>
    <div id="screen" class="screen"></div>
    <transition mode="out-in" name="leftSlide">
      <big-left ref="Left" class="left" v-show="leftShow"></big-left>
    </transition>
    <transition mode="out-in" name="rightSlide">
      <bigRight ref="Right" class="right" v-show="rightShow"></bigRight>
    </transition>
    <div class="bottom">
      <div
        class="btn"
        v-for="(item, key) in akbutton"
        :key="key"
        @click="cameraMove(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import BaseNet from "@/three/baseNet";
import { akbutton, tube, textSp, pengquan } from "@/assets/mock/ankao.js";
import * as THREE from "three";
import bigLeft from "@/components/code";
import bigRight from "@/components/textcode";
import { createSprite } from "@/three/sprite";
let app,
  camera,
  baseNet,
  scene,
  modelUrlPath,
  modelUrlPathName,
  renderer,
  cameraCurve,
  renderOrder = 0,
  controls,
  clock,
  cityModel,
  // 车轮数组
  cavasHtmlGroup = new THREE.Group(),
  buildingGroup = new THREE.Group(),
  meterGroup = new THREE.Group(),
  textureLoader = new THREE.TextureLoader(),
  speed = 0.001,
  isCameraMove = false,
  isPenquan = false,
  isPause = false,
  dataControls = {},
  progress = 0;

export default {
  name: "Home",
  components: {
    bigLeft,
    bigRight,
  },
  data() {
    return {
      isCarMove: false,
      akbutton: akbutton,
      isLoaded: false,
      loading: null,
      leftShow: false,
      rightShow: false,
      modelPath: null,
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.initLight();
      app.loaderSky("texture/sky3/");
      // 点击精灵飞行
      // app.initRaycaster((obj) => {
      //   console.log(app.camera)
      //   console.log(app.controls)
      //   if (obj.isSprite) {
      //     textSp.forEach((item) => {
      //       if (item.name === obj.name) {
      //         app.flyTo({
      //           position: item.cameraPosition,
      //           duration: 1500,
      //         });
      //       }
      //     });
      //   }
      // });
      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(12, 144, -132);
      clock = new THREE.Clock();
      let apath = modelUrlPath ||"model/ankao/0321/";
      let aName = modelUrlPathName ||"0321b";
      cityModel = await app.loaderGltfDracoModel(
          apath,
          aName,
        (percentage) => {
          // if(this.loading&&this.loading.visible)
          // this.loading.text = "资源正在加载中，请稍后"+"···"+percentage+"%";
        }
      );
      // cityModel = await app.loaderObjModel("model/ankao/0310/", "box", "box");
      // cityModel = await app.loaderonlyObjModel("model/ankao/", "1111");
      // cityModel =await app.loadGLBModel("model/ankao/","55555");
      // console.log(cityModel);
      cityModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
            model.material &&
            model.material.map &&
            model.material.map.encoding
          ) {
            model.material.map.encoding = THREE.LinearEncoding;
          }
          // model.material.emissive =  model.material.color;
          // model.material.emissiveMap = model.material.map ;
          // If texture is used for color information, set colorspace.
          //           model.material.map.encoding = THREE.sRGBEncoding;
          //
          // // UVs use the convention that (0, 0) corresponds to the upper left corner of a texture.
          //           model.material.map.flipY = false;

          // if(model.material.map&&model.material.map.image){
          //   // var textureLoader = new THREE.TextureLoader(); // 纹理加载器
          //   // var texture = textureLoader.load('model/ankao/maps/'+model.material.map.name);
          //   // model.material.map = texture;
          //   // console.log(model)
          //   // console.log(model.material.map.image.currentSrc);
          //   if(model.material.map.name&&model.material.map.name.indexOf("baiqiang")>=0)
          //     console.log(model)
          //   // console.log(model.material.map.name)
          // }
          // app.shaderObj(model);
        }
      });
      buildingGroup.add(cityModel);
      buildingGroup.renderOrder = renderOrder++;
      scene.add(buildingGroup);


      scene.add(meterGroup);
      app.loadMeter(meterGroup,[96, 5, 420]);


      // 添加平面
      let plane = app.loaderPlane("texture/caodi.jpg");
      plane.position.set(0, -0.5, 0);
      plane.renderOrder = 2;
      scene.add(plane);

      // 绿色线贴图
      // let greenLineTexture = textureLoader.load("texture/green_line.png");
      // greenLineTexture.wrapS = greenLineTexture.wrapT = THREE.RepeatWrapping;
      // greenLineTexture.repeat.set(1, 1);
      // greenLineTexture.needsUpdate = true;
      //
      // let tubeGreenMaterial = new THREE.MeshBasicMaterial({
      //   map: greenLineTexture,
      //   side: THREE.BackSide,
      //   transparent: true,
      // });
      // 创建文本
      textSp.forEach((item) => {
        createSprite(cavasHtmlGroup, item.name, item.position);
      });
      scene.add(cavasHtmlGroup);
      // 创建路线
      // akbutton.forEach((item) => {
      //   if (item.type == "line") {
      //     let tube = app.loaderTube(item.route, tubeGreenMaterial);
      //     buildingGroup.add(tube);
      //   }
      // });
      // 创建用水管道
      tube.forEach((item) => {
        if (item.type == "tube") {
          let tube = app.loaderWaterTube(item.route);
          buildingGroup.add(tube);
        }
      });
      // let pq = app.createPoints();
      // this.showPenquan();
      this.loading.close();
      camera.position.set(-41, 1021, 312);

      app.flyTo({
        position: [433.456706289105, 219.68312417497387, 540.5150079031882],
        controls: [108.21390562685808, -129.51278441906786, 67.9566070128961],
        duration: 1500,
      });
      this.loadShebei();
      app.render(() => {
        controls.update(clock.getDelta());
        renderer.render(scene, camera);
        TWEEN.update();

        buildingGroup.traverse((model) => {
          if (model.jc_type == "waterTube") {
            model.material.map.offset.x -= 0.01;
          }

          if (isPenquan &&model.jc_type == "penquan") {
            let size = model.jc_size;
            for (var p = 0; p < 500; p++) {
              var particle = model.geometry.vertices[p];
              particle.x += particle.velocity.x;
              particle.y += particle.velocity.y;
              particle.z += particle.velocity.z;
              particle.velocity.y -= size / 8;
              if (particle.y < size * 2.5) {
                particle.x = particle.y = particle.z = 0;
                var dX, dY, dZ;
                dY = Math.random() * size + size * 2.5;
                dX = Math.random() * size - size / 2;
                dZ = Math.random() * size - size / 2;
                particle.velocity = new THREE.Vector3(dX / 1, dY / 1, dZ / 1);
              }
            }
            model.geometry.verticesNeedUpdate = true;
          }

          if ( model.jc_type == "penguan") {
            const vertices = [];
            for (var col = 0; col < 20; col++) {
              let py = model.jc_pypz[col][0];
              let pz = model.jc_pypz[col][1];
              for (var row = 0; row < 30; row++) {
                let i = row / 30 + (1 / 30) * Math.random();
                const linepoints = model.jc_line1.getPointAt(i);
                let ppy = (py * row) / 30 + (row / 30) * Math.random() * 3;
                let ppz = (pz * row) / 30 + (row / 30) * Math.random() * 3;
                vertices.push(
                  linepoints.x,
                  linepoints.y + ppy,
                  linepoints.z + ppz
                );
              }
            }
            model.geometry.setAttribute(
              "position",
              new THREE.Float32BufferAttribute(vertices, 3)
            );
          }
        });
        if (isCameraMove) {
          if (progress <= 1) {
            const point = cameraCurve.getPointAt(progress); //获取样条曲线指定点坐标，作为相机的位置
            // const pointBox = _that.cameraCurve.getPointAt(_that.progress + 0.001 * 10) //获取样条曲线指定点坐标
            const tangent = cameraCurve.getTangentAt(progress);
            const lookAtVec = tangent.add(point);
            // const lookAtVec = cameraCurve.getPointAt(progress+speed);
            // lookAtVec.y = lookAtVec.y - 0.4;
            app.camera.position.set(point.x, point.y, point.z);
            if (lookAtVec.x == 334 && lookAtVec.z == 182 && lookAtVec.y < 48)
              lookAtVec.x = lookAtVec.x - 5;
            // if(lookAtVec.y=48)
            //   lookAtVec.x = lookAtVec.x-5;

            app.controls.target.set(lookAtVec.x, lookAtVec.y, lookAtVec.z);

            // console.log(point)
            // console.log('看向：')
            // console.log(lookAtVec)
            // app.controls.target.set(point.x, point.y , point.z-10);
            app.controls.update();
            progress += speed;
          } else {
            if (dataControls.lineName === "绿化喷滴灌") {
              this.showPenquan();
              this.rightShow = true;
              this.$refs["Right"].showPD();
              console.log("showPenquan");
            }

            isCameraMove = false;
            isPause = false;
            progress = 0;
          }
        }
      });
    },
    showPenquan() {
      if (isPenquan) return;
      isPenquan = true;
      pengquan.forEach((item) => {
        let pq = app.createPenQuan(0.1, [item[0], item[1], item[2]]);
        buildingGroup.add(pq);
      });
    },
    removePenquan() {
      if (!isPenquan) return;
      isPenquan = false;
      app.removeMesh("penquan");
    },
    async loadShebei() {
      let apath = "model/ankao/0321/";
      let aName = "0321a";
      let sebei = await app.loaderGltfDracoModel(
          apath,
          aName,
          (percentage) => {

          }
      );

      sebei.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
              model.material &&
              model.material.map &&
              model.material.map.encoding
          ) {
            model.material.map.encoding = THREE.LinearEncoding;
          }

        }
      });
      buildingGroup.add(sebei);
    },
    cameraMove(item) {
      if (isCameraMove && !isPause) {
        isCameraMove = false;
        isPause = true;
        console.log("暂停");
        return;
      } else if (!isCameraMove && isPause) {
        console.log("播放");
        isCameraMove = true;
        isPause = false;
        return;
      }
      if (item.type == "line") {
        // this.$refs["Left"].show = false;
        cameraCurve = item.route;
        speed = item.speed;
        isCameraMove = true;
        dataControls.lineName = item.name;
        if (item.name != "厂区") {
          this.leftShow = false;
        } else if (item.name == "厂区") {
          // this.$refs["Left"].getdom();
          this.leftShow = true;
          this.$refs["Left"].showData();
        }
        if (dataControls.lineName != "绿化喷滴灌") {
          this.removePenquan();
          this.rightShow = false;
          console.log("removePenquan");
        }
      } else if (item.type == "camera") {
        // this.leftShow = !this.leftShow;
        // this.rightShow = !this.rightShow;
        this.leftShow = false;
        this.rightShow = false;


        app.flyTo({
          position: item.cameraPosition,
          controls: item.controls,
          duration: 1500,
        });
      }
    },
    confirm(mag) {
      this.$message({
        message: mag,
        type: "success",
        duration: 3000,
      });
    },
  },
  mounted() {
    this.baseNet = new BaseNet();
    this.baseNet.IsAgent(confirm);
    this.baseNet.getGPU(confirm);

    let ip = this.baseNet.getQueryString("IP");
    modelUrlPath = this.baseNet.getQueryString("modelUrlPath");
    modelUrlPathName = this.baseNet.getQueryString("modelUrlPathName");

    ip = ip.replaceAll("%3A", ":").replaceAll("%2F", "/");
    let USER_ID = this.baseNet.getQueryString("USER_ID");
    let TOKEN = this.baseNet.getQueryString("TOKEN");

    var success = this.baseNet.checkToken(ip,USER_ID,TOKEN);
    if(!success){
      return;
    }

    this.$refs["Left"].codeInit(ip, USER_ID, TOKEN);
    this.loading = this.$loading({
      lock: true,
      text: "资源正在加载中，请稍后",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 1)",
    });
    this.initZThree();
  },
};
</script>
<style lang='less' scoped>
#app {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  display: flex;
  top: 0;
  left: 0;
  z-index: 10;
}
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .left {
    width: 20%;
    height: 90%;
    position: fixed;
    top:12%;
    left: 2%;
  }
  .right {
    width: 26%;
    height: 90%;
    position: fixed;
    top:12%;
    right: 20%;
  }
  .bottom {
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      background: url("./../assets/images/bnt.png") 100% 100% no-repeat;
      width: 91px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      color: #fff;
      font-size: 16px;
      margin: 0 6px;
    }
  }
}
.main::-webkit-scrollbar {
  display: none;
}
.sprite-canvas {
  position: absolute;
  width: 1024px;
  height: 1024px;
  font-size: 128px;
  top: 0;
  box-sizing: border-box;
  background-color: transparent;
  color: #fff;
  text-align: center;
  .sprite-layer {
    margin-top: 60%;
    background-color: greenyellow;
    padding: 1% 2%;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
}
.leftSlide-enter-active,
.leftSlide-leave-active {
  transition: all 2s ease; // 动画时间为2秒
  width: 0;
}

.leftSlide-enter,
.leftSlide-leave-to {
  transform: translateX(-100%); // 初始位置为右侧，结束位置为左侧
  opacity: 0;
  //width: 100;
}

.rightSlide-enter-active,
.rightSlide-leave-active {
  transition: all 2s ease; // 动画时间为2秒
  //width: 100;
}

.rightSlide-enter,
.rightSlide-leave-to {
  transform: translateX(100%); // 初始位置为右侧，结束位置为左侧
  width: 0;
  opacity: 0;
}
</style>
