<template>
  <div class="chart">
    <div v-if="dataEmpty" class="error">
      <i class="el-icon-folder-opened" />
      <p>当前无数据</p>
    </div>
    <div ref="chart" class="chart" />
  </div>
</template>

<script>
import chart from '@/components/mixins/chart'

export default {
  name: 'PieChart',
  mixins: [chart],
  props: {
    data: {
      type: [Object, Array],
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chart: null,
      dataEmpty: false
    }
  },
  watch: {
    data(newVal) {
      this.setData(newVal)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs['chart'])

      this.chart.setOption({
        color: ['rgb(74, 33, 235)', 'rgb(244, 137, 52)'],
        tooltip: {
          trigger: 'item'
        },
        series: []
      })
    },
    // 请求图表数据
    init() {
      if (this.data && this.data.length === 0) {
        this.dataEmpty = true
      }

      if (!this.dataEmpty) {
        this.initChart()
      }

      this.setData(this.data)
    },
    // 更新数据
    setData(newData) {
      if (!this.chart) {
        return
      }

      // 重新给chart赋值
      this.chart.setOption({
        series: {
          name: this.title,
          type: 'pie',
          center: ['50%', '55%'],
          radius: ['30%', '60%'],
          data: this.data,
          label: {
            normal: {
              color: '#E6E7EE',
              fontSize: 14,
              fontWeight: 600,
              formatter: `{b} \n {d}%`
            }
          },
          labelLine: {
            lineStyle: {
              color: '#E6E7EE'
            }
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.chart {
  width: 100%;
  height: 100%;
}

.error {
  padding: 16px 0;
  text-align: center;
}
</style>
