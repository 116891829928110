<template>
  <div class="right animated slideInRight">
    <box title="交通工具拥有量" class="box">
      <scatter-chart v-if="scatterData" :data="scatterData" />
    </box>
    <box title="本月违章数" class="box">
      <pie-chart v-if="pieData" :data="pieData" />
    </box>
    <box title="区域拥堵排名" class="box">
      <bar-chart v-if="barData" :data="barData" />
    </box>
  </div>
</template>

<script>
import box from "@/components/box";
import scatterChart from "@/components/chart/scatterChart";
import pieChart from "@/components/chart/pieChart";
import barChart from "@/components/chart/barChart";

export default {
  name: "",
  components: {
    box,
    scatterChart,
    pieChart,
    barChart,
  },
  mounted() {
    this.init();
  },
  props: {},
  data() {
    return {
      scatterData: null,
      pieData: null,
      barData: null,
    };
  },
  methods: {
    init() {
      this.getScatterChartData();
      this.getPieChartData();
      this.getBarChartData();
    },
    /**
     * 获取散点图数据
     */
    getScatterChartData() {
      this.scatterData = [
        {
          sourceName: "白云区",
          persons: 45,
          name: "白云区",
        },
        {
          sourceName: "南明区",
          persons: 66,
          name: "南明区",
        },
        {
          sourceName: "云岩区",
          persons: 88,
          name: "云岩区",
        },
        {
          sourceName: "乌当区",
          persons: 100,
          name: "乌当区",
        },
        {
          sourceName: "花溪区",
          persons: 60,
          name: "花溪区",
        }
      ];
    },
    /**
     * 获取饼图数据
     */
    getPieChartData() {
      this.pieData = [
        { name: "超速", value: 112421, rate: 49.8373 },
        { name: "逆行", value: 113155, rate: 50.1627 },
      ];
    },
    /**
     * 获取柱状图数据
     */
    getBarChartData() {
      this.barData = {
        xAxisDatas: [
          "区域A",
          "区域B",
          "区域C",
          "区域D",
          "区域E",
        ],
        yAxisDatas: [
          {
            name: "",
            data: [139, 241, 268, 284, 295],
          },
        ],
      };
    },
  },
};
</script>
<style lang='less' scoped>
.right {
  position: fixed;
  top: 80px;
  right: 1%;
  width: 20%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  .box {
    width: 100%;
    height: 30.7%;
  }
}
</style>
