import axios from "axios";
import * as THREE from 'three'
import {
  getDistance,
  getRhumbLineBearing
} from "geolib";
import {
  BufferGeometryUtils
} from "three/examples/jsm/utils/BufferGeometryUtils.js";
// 中心点数组
const center = [113.3357207, 23.1368946];
// 医院地理位置ID
const hospitalIds = [
  "relation/9037684",
  "way/647731486",
  "way/647731472",
  "way/508072400",
  "way/508072399",
  "way/508072401",
  "way/334133881",
  "way/647747304",
  "way/334133880",
  "way/647731479",
  "way/647731477",
  "way/647731476",
  "way/647731474"
];
// 初始化创建组
let iR = new THREE.Group();
iR.scale.set(20, 20, 20);
iR.name = "建组";

let iR_Road = new THREE.Group();
iR_Road.name = "公路";
iR_Road.scale.set(20, 20, 20);

let iR_Line = new THREE.Group();
iR_Line.name = "公路动画线";
iR_Line.scale.set(20, 20, 20);

// 包围盒数组对象
let collider_building = [];

// 线条数组对象
let linelist = [];

// 线条运动速度
let Animated_Line_Speed = 0.004;

// 线条长度数组
let Animated_Line_Distances = [];

// 建组数组对象
let geos_building = [];

const MAT_HOSPITAL_BUILDING = new THREE.LineBasicMaterial({
  color: "#57d8ff",
  transparent: true,
  linewidth: 5,
  opacity: 1.0,
});

const MAT_BUILDING = new THREE.LineBasicMaterial({
  color: "#57d8ff",
  transparent: true,
  linewidth: 5,
  opacity: 1.0,
});

// 线材质
const MAT_ROAD = new THREE.LineBasicMaterial({
  color: 0x00ff00,
  linewidth: 10
});

const MAT_ROAD_HIGEWAY = new THREE.MeshBasicMaterial({
  // map: spriteLightTexture,
  side: THREE.BackSide,
  transparent: true
});

/**
 * 获得地图数据
 */
export function createBuildingRoad(app) {
  return new Promise(resolve => {
    axios({
      url: "/json/export.json"
    }).then(res => {
      LoadBuildings(res.data, app);

      app.scene.add(iR);
      app.scene.add(iR_Road);
      app.scene.add(iR_Line);
      resolve(true)
    });
  })
}

/**
 * 加载地图模型
 */
function LoadBuildings(data, app) {
  let features = data.features;

  for (let i = 0; i < features.length; i++) {
    let fel = features[i];
    if (!fel["properties"]) return;

    let info = fel.properties;

    // building 建筑   highway 公路
    if (info["building"]) {
      addBuilding(fel.geometry.coordinates, info, info["buildingLevels"]);
    } else if (info["highway"]) {
      if (fel.geometry.type == "LineString") {
        if (
          info["highway"] === "trunk" ||
          info["highway"] === "motorway"
        ) {
          addRoadTrunk(fel.geometry.coordinates, info);
        } else {
          addRoad(fel.geometry.coordinates, info);
        }
      }
    }
  }

  // linelist.forEach((e, i) => {
  //   const line2 = app.createAnimateLine({
  //     type: "pipe",
  //     pointList: e,
  //     material: MAT_ROAD_HIGEWAY,
  //     number: 70,
  //     radius: 0.1
  //   });

  //   iR_Road.add(line2);
  // });

  let lineMaterial = new THREE.LineBasicMaterial({
    color: "#57d8ff",
    transparent: true,
    linewidth: 5,
    opacity: 1.0,
  });

  let mergeGeometry = BufferGeometryUtils.mergeBufferGeometries(
    geos_building
  );

  let edges = new THREE.EdgesGeometry(mergeGeometry, 1);
  let mesh = new THREE.LineSegments(edges, lineMaterial);

  // let mesh = new THREE.Mesh(mergeGeometry, MAT_BUILDING);

  iR.add(mesh);
}

// 区间变化
function conversion(max1, min1, max2, min2, value) {
  if (max1 === min1) {
    return max2;
  }
  const res = ((value - min1) / (max1 - min1)) * (max2 - min2) + min2;
  return parseInt(res);
}

/**
 * 添加建筑
 * @param {Array} data 坐标数组
 * @param {Object} info 建筑信息
 * @param {Number} height 建组高度
 */
function addBuilding(data, info, height = 1) {
  height = height ? height : 1;

  let shape, geometry;
  // 建筑物的孔
  let holes = [];

  for (let i = 0; i < data.length; i++) {
    let el = data[i];

    if (i == 0) {
      // 建筑路劲
      shape = genShape(el, center);
    } else {
      // 当前孔的路径
      holes.push(genShape(el, center));
    }
  }

  // 将孔添加到当前建组中
  for (let i = 0; i < holes.length; i++) {
    shape.holes.push(holes[i]);
  }

  geometry = genGeometry(shape, {
    curveSegments: 1,
    depth: conversion(111, 1, 4, 1, height),
    // depth: 0.1 * height,
    bevelEnabled: false
  });

  geometry.rotateX(Math.PI / 2);
  geometry.rotateZ(Math.PI);

  geos_building.push(geometry);

  let helper = genHelper(geometry);
  if (helper) {
    helper.name = info["name"] ? info["name"] : "Building";
    helper.info = info;
    collider_building.push(helper);
  }
}

/**
 * 添加公路
 * @param {Array} d 坐标数组
 * @param {Object} info 建筑信息
 */
function addRoadTrunk(d, info) {
  // 点数组
  let points = [];

  for (let i = 0; i < d.length; i++) {
    if (!d[0][1]) return;

    let el = d[i];

    if (!el[0] || !el[1]) return;

    let elp = [el[0], el[1]];

    // 位置转换
    elp = GPSRelativePosition([elp[0], elp[1]], center);

    points.push([elp[0], 0.2, elp[1]]);
  }

  linelist.push(points);
}

/**
 * 添加公路
 * @param {Array} d 坐标数组
 * @param {Object} info 建筑信息
 */
function addRoad(d, info) {
  // 点数组
  let points = [];

  for (let i = 0; i < d.length; i++) {
    if (!d[0][1]) return;

    let el = d[i];

    if (!el[0] || !el[1]) return;

    let elp = [el[0], el[1]];

    // 位置转换
    elp = GPSRelativePosition([elp[0], elp[1]], center);

    points.push(new THREE.Vector3(elp[0], 0.5, elp[1]));
  }

  // 通过点队列设置该 BufferGeometry 的 attribute
  let geometry = new THREE.BufferGeometry().setFromPoints(points);

  geometry.rotateZ(Math.PI);

  let line = new THREE.Line(geometry, MAT_ROAD);
  line.info = info;
  // 计算LineDashedMaterial所需的距离的值的数组
  line.computeLineDistances();

  iR_Road.add(line);
  line.position.set(line.position.x, 0.5, line.position.z);

  // if (FLAG_ROAD_ANI) {
  //   let lineLength =
  //     geometry.attributes.lineDistance.array[
  //       geometry.attributes.lineDistance.count - 1
  //     ];
  //   // 当前线的长度大于0.8， 开启动画
  //   if (lineLength > 0.2) {
  //     let aniLine = addAnimatedLine(geometry, lineLength);
  //     iR_Line.add(aniLine);
  //   }
  // }
}

/**
 * 添加线条动画
 * @param {Array} geometry
 * @param {Number} length 线条长度
 * @return {Object} animatedLine 线条
 */
function addAnimatedLine(geometry, length) {
  let animatedLine = new THREE.Line(
    geometry,
    new THREE.LineDashedMaterial({
      color: 0xff0000
    })
  );
  animatedLine.material.transparent = true;
  animatedLine.position.y = 0.5;
  // 短划线长度
  animatedLine.material.dashSize = 0;
  // 间隔长度
  animatedLine.material.gapSize = 1000;

  Animated_Line_Distances.push(length);

  return animatedLine;
}

/**
 * 更新线条动画
 */
function UpdateAniLines() {
  if (iR_Line.children.length <= 0) return;

  for (let i = 0; i < iR_Line.children.length; i++) {
    let line = iR_Line.children[i];

    let dash = parseInt(line.material.dashSize);
    let length = parseInt(Animated_Line_Distances[i]);

    if (dash > length) {
      line.material.dashSize = 0;
      line.material.opacity = 1;
    } else {
      line.material.dashSize += Animated_Line_Speed;
      line.material.opacity =
        line.material.opacity > 0 ? line.material.opacity - 0.002 : 0;
    }
  }
}

/**
 * 添加建筑
 * @param {Array} points 坐标数组
 * @param {Array} center 地理位置中心点
 * @return {Object}
 */
function genShape(points, center) {
  let shape = new THREE.Shape();

  for (let i = 0; i < points.length; i++) {
    let elp = points[i];
    elp = GPSRelativePosition(elp, center);

    if (i == 0) {
      shape.moveTo(elp[0], elp[1]);
    } else {
      shape.lineTo(elp[0], elp[1]);
    }
  }

  return shape;
}

/**
 * 生成Geometry
 * @param {Object} shape shape对象
 * @param {Object} settings 建筑配置项
 * @return {Object} 建组对象
 */
function genGeometry(shape, settings) {
  // 挤压缓冲几何体
  let geometry = new THREE.ExtrudeBufferGeometry(shape, settings);
  geometry.computeBoundingBox();

  return geometry;
}

/**
 * 建组包围盒子
 * @param {Object} geometry
 */
function genHelper(geometry) {
  if (!geometry.boundingBox) {
    geometry.computeBoundingBox();
  }

  let box3 = geometry.boundingBox;
  // 检测包围盒是不是无穷大
  if (!isFinite(box3.max.x)) {
    return false;
  }

  let helper = new THREE.Box3Helper(box3, 0xffff00);
  helper.updateMatrixWorld();
  return helper;
}

/**
 * GPS经纬度转化
 * @param {Array} objPosi 坐标数组
 * @param {Array} centerPosi 中心点
 * @return {Array} 转化后的数组
 */
function GPSRelativePosition(objPosi, centerPosi) {
  let dis = getDistance(objPosi, centerPosi);

  // Get bearing angle
  let bearing = getRhumbLineBearing(objPosi, centerPosi);

  // Calculate X by centerPosi.x + distance * cos(rad)
  let x = centerPosi[0] + dis * Math.cos((bearing * Math.PI) / 180);

  // Calculate Y by centerPosi.y + distance * sin(rad)
  let y = centerPosi[1] + dis * Math.sin((bearing * Math.PI) / 180);

  // Reverse X (it work)
  return [-x / 100, y / 100];
}