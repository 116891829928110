import * as THREE from 'three';

export function createSprite(group, name, position) {
  const html = `
                    <div class="sprite-canvas">
                        <span class="sprite-layer">${name}</span>
                    </div>`;

  document.body.insertAdjacentHTML("beforeend", html);
  const element = document.body.lastChild;
  element.style.zIndex = -1;
  html2canvas(element, {
    backgroundColor: "transparent"
  }).then(canvas => {
    let texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;

    let spriteMaterial = new THREE.SpriteMaterial({
      map: texture
    });
    let sprite = new THREE.Sprite(spriteMaterial);
    sprite.name = name;
    sprite.position.set(...position);
    sprite.scale.set(60, 60, 60);
    // sprite.rotateX(Math.PI/2);
    group.add(sprite);
    document.body.removeChild(element);
  });
}
