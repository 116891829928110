<template>
  <div class="main">

    <div id="screen" class="screen"></div>
    <transition mode="out-in" name="leftSlide">
      <big-left ref="Left" class="left" v-show="rightShow"></big-left>
    </transition>
    <transition mode="out-in" name="rightSlide">
      <bigRight ref="Right" class="right" v-show="rightShow"></bigRight>
    </transition>
    <div class="bottom">
      <div
          class="btn"
          v-for="(item, key) in address"
          :key="key"
          @click="fly(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import ModelSplit from "@/three/baozha";
import bigRight from "@/components/textcode";
import bigLeft from "@/components/excelcode";
import address from "@/assets/mock/product.js";
import * as THREE from "three";
import BaseNet from "@/three/baseNet";

let app,
    camera,
    baseNet,
    scene,
    renderer,
    renderOrder = 0,
    modelUrlPath,
    modelUrlPathName,
    controls,
    clock,
    cityModel,
    buildingGroup = new THREE.Group(),
    skyTexture,
    modelSplit,
    bloomComposer

export default {
  name: "Home",
  components: {
    bigLeft,
    bigRight,
  },
  data() {
    return {
      // 小车移动状态
      isCarMove: false,
      address: address,
      isLoaded: false,
      loading: null,
      rightShow: false,
      isOpen: false,
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.initLight(0.4);
      // skyTexture = app.loaderSky("texture/sky2/");
      bloomComposer = app.bloomComposer();
      modelSplit = new ModelSplit();
      // 点击精灵飞行
      app.initRaycaster((obj) => {
        console.log(app.camera)
        console.log(app.controls)
      });
      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(2, 44, -32);
      clock = new THREE.Clock();

      renderer.setClearColor( 0x333333 );

      let apath = modelUrlPath ||"model/meter/0327/";
      let aName = modelUrlPathName ||"111111";
      apath = "model/meter/aaaaxzcxzc/";
      aName = "aaaaadsdsds";

      cityModel = await app.loaderGltfDracoModel(
          apath,
          aName,
          (percentage) => {
            // if(this.loading&&this.loading.visible)
            // this.loading.text = "资源正在加载中，请稍后"+"···"+percentage+"%";
          }
      );

      cityModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
              model.material &&
              model.material.map &&
              model.material.map.encoding
          ) {
            model.material.side = THREE.DoubleSide//正反面显示
          }
        }
      });
      console.log(cityModel);
      cityModel.position.set(0,0,0);
      cityModel.scale.set(100,100,100)
      buildingGroup.add(cityModel);
      buildingGroup.renderOrder = renderOrder++;
      scene.add(buildingGroup);

      // // 添加平面
      // let plane = app.loaderPlane("texture/plane.png");
      // plane.position.set(0, -30, 0);
      // plane.renderOrder = 2;
      // scene.add(plane);

      const grid = new THREE.Group();

      const grid1 = new THREE.GridHelper( 500, 30, 0x555555 );
      grid1.material.color.setHex( 0x555555 );
      grid1.material.vertexColors = false;
      grid.add( grid1 );

      const grid2 = new THREE.GridHelper( 500, 6, 0x111111 );
      grid2.material.color.setHex( 0x111111 );
      // grid2.material.depthFunc = THREE.AlwaysDepth;
      grid2.material.vertexColors = false;
      grid.add( grid2 );
      grid.position.y = -40;
      scene.add(grid);

      this.loading.close();
      let text = [
        "DN40-300(T50/PN16)",
        [
          '· 多点调节的专利技术，叶轮受水力冲击平衡',
          '· 真正可拆互换式机芯，维修便捷',
          '· 高强度塑料外壳保护的计数器可360°内任意旋转',
          '· 钢化玻璃计数器防水等级为IP68保证了计数器表面不起雾',
          '· 应用水力动平衡的技术，大大提高了流量低区（灵敏度）和高区（过载）性能',
          '· 表壳内外环氧树脂喷涂防腐处理，整表符合国家卫生部和欧洲饮用水标准要求'
        ],
        "水平螺翼可拆式冷水水表",
      ];
      this.$refs["Right"].showPD(text[0],text[1],text[2]);
      modelSplit.quit();
      modelSplit.setOnlySonSplitModel(cityModel);


      app.flyTo({
        position: [41,56,135],
        controls: [0, 0,  0],
        duration: 1500,
      });

      app.render(() => {
        controls.update(clock.getDelta());
        // renderer.render(scene, camera);
        bloomComposer.render();
        modelSplit.update();
        TWEEN.update();
      });
    },
    fly(item) {
      if(item.name=='复位'){
        app.flyTo({
          position: item.cameraPosition,
          controls: item.controls,
          duration: 1500,
        });
      }else if(item.name=='透视'){
        cityModel.traverse((model) => {
          if (model.isMesh) {
            app.shaderObj(model);
          }
        });
      }else if(item.name=='切换'){
        if(this.isOpen){
          modelSplit.quitSplit();
        }else {
          modelSplit.startSplit();
        }
        this.isOpen = !this.isOpen;
      }
      else if(item.name=='特征'){
        // modelSplit.quit();

        // modelSplit.setSplitModel(cityModel);
        if(this.rightShow){
          this.rightShow = false;
          // app.meshMove(cityModel,{x:cityModel.position.x+50,y:cityModel.position.y,z:cityModel.position.z});
          // this.controls.target.setX(this.controls.targetX+50)
          // this.controls.update()
        }else{
          this.rightShow = true;
          // app.meshMove(cityModel,{x:cityModel.position.x-50,y:cityModel.position.y,z:cityModel.position.z});
          // this.controls.target.setX(this.controls.targetX-50)
          // this.controls.update()
        }
      }
    },
  },
  mounted() {

    this.baseNet = new BaseNet();
    this.baseNet.IsAgent(confirm);
    this.baseNet.getGPU(confirm);

    let ip = this.baseNet.getQueryString("IP");
    modelUrlPath = this.baseNet.getQueryString("modelUrlPath");
    modelUrlPathName = this.baseNet.getQueryString("modelUrlPathName");

    ip = ip.replaceAll("%3A", ":").replaceAll("%2F", "/");
    let USER_ID = this.baseNet.getQueryString("USER_ID");
    let TOKEN = this.baseNet.getQueryString("TOKEN");
    var success = this.baseNet.checkToken(ip,USER_ID,TOKEN);
    // if(!success){
    //   return;
    // }


    this.loading = this.$loading({
      lock: true,
      text: "资源正在加载中，请稍后",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 1)",
    });
    this.initZThree();
  },
};
</script>

<style lang='less' scoped>
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  .screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .left {
    width: 20%;
    height: 88%;
    position: fixed;
    top: 9%;
    left: 2%;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    color: #fff;
    line-height: 100px;
    background: url("./../assets/images/top.png") center bottom no-repeat;
    font-size: 40px;
    text-align: center;
    z-index: 2;
  }
  .right {
    width: 30%;
    height: 90%;
    position: fixed;
    top: 9%;
    right: 2%;
  }
  .bottom {
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      background: url("./../assets/images/bnt.png") 100% 100% no-repeat;
      width: 91px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      color: #fff;
      font-size: 16px;
      margin: 0 6px;
    }
  }
}
</style>
<style lang="less">
.sprite-canvas {
  position: absolute;
  width: 1024px;
  height: 1024px;
  font-size: 128px;
  top: 0;
  box-sizing: border-box;
  background-color: transparent;
  color: #fff;
  text-align: center;
  .sprite-layer {
    margin-top: 60%;
    background-color: blue;
    padding: 1% 2%;
  }
}
.leftSlide-enter-active,
.leftSlide-leave-active {
  transition: all 2s ease; // 动画时间为2秒
  width: 0;
}

.leftSlide-enter,
.leftSlide-leave-to {
  transform: translateX(-100%); // 初始位置为右侧，结束位置为左侧
  opacity: 0;
  //width: 100;
}
.rightSlide-enter-active,
.rightSlide-leave-active {
  transition: all 2s ease; // 动画时间为2秒
  //width: 100;
}

.rightSlide-enter,
.rightSlide-leave-to {
  transform: translateX(100%); // 初始位置为右侧，结束位置为左侧
  width: 0;
  opacity: 0;
}
</style>
