<template>
  <div class="box">
    <div class="title">
      <div class="con">
        <div class="text">{{ title }}</div>
        <slot class="slot" name="right" />
      </div>
    </div>
    <div class="chart">
      <slot :title="title" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: [Array, Object],
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  background-image: url("./../assets/images/box.png");
  background-size: 100% 100%;
  // opacity:0.8;
  position: relative;
  .title {
    width: 88%;
    position: absolute;
    top: 26px;
    left: 26px;
    z-index: 20;
    font-size: 20px;
    font-weight: 600;
    color: #f7fbfc;
    letter-spacing: 2px;
    opacity:1;

    .con {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      opacity:1;
    }
  }
  .chart {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
