import $ from "@/assets/js/jquery.min";

/**
 *
 */
export default class BaseNet{
    constructor(){
        this.initData();
    }
    initData(){

    }

    getQueryString(name) {
        var url = window.location.href; //获取url中"?"符后的字串
        if (url.indexOf("?") != -1) {
            var urls = url.split("?");
            var strs = urls[1].split("&");
            for (var i = 0; i < strs.length; i++) {
                var item = strs[i].split("=");
                if (item[0] == name) {
                    return item[1];
                }
            }
        }
        return "";
    }
    //设备信息
    IsAgent(confirm) {
        var os = require("os");
        var totalmem = os.totalmem();
        //处理非数字
        console.log(totalmem);
        totalmem = (totalmem - 0).toLocaleString();
        totalmem = totalmem.toString().replace(/\$|\,/g, "");
        console.log(totalmem);
        if (totalmem <= 20480) {
            confirm("电脑内存不足，项目加载时间可能延长，请耐心等待");
        }
        return totalmem;
    }
    getGPU(confirm) {
        var canvas = document.createElement("canvas"),
            gl = canvas.getContext("experimental-webgl"),
            debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
        var info = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        if (info == undefined || info == null) {
            confirm("未检测到您的显卡配置，加载时间可能延长，请耐心等待");
        }
        return info;
    }
    checkToken(ip,id,token) {
        $.ajaxSettings.async = false;
        $.get(
            ip + "/appchange/checkTokenUser",
            {
                USER_ID: id,
                TOKEN: token,
            },
            function (data) {
                if (data.INFO == "success") {
                    return true;
                } else {
                    return false;
                }
            }
        );
        $.ajaxSettings.async = true;
    }
}
