<template>
  <div>
    <div class="echart" id="mychart" ref="ele" :style="myChartStyle"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import $ from "@/assets/js/jquery.min.js";

export default {
  data() {
    return {
      myChartStyle: { width: "100%", height: "400px" }, //图表样式
    };
  },
  mounted() {
    //2 error
    // this.showChartData();
  },
  methods: {
    showChartData(xdata, ydata) {
      // let this_ = this;
      console.log(1111111)
      const YslChartoption = {
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "shadow",
          },
          // formatter: function (params) {
          //   let axisValue = "";
          //   that.tablePage.forEach(function (item, index) {
          //     if (
          //       params[0].axisValue == format(item.DATA_DATEOLD, "MM-dd HH:mm")
          //     ) {
          //       axisValue =
          //         params[0].axisValue +
          //         "<br/>&nbsp;&nbsp;用量：" +
          //         item.USE_SUM +
          //         "&nbsp;m³ <br/>&nbsp;&nbsp;读数：" +
          //         item.MAX_READ_SUM +
          //         "&nbsp;m³";
          //     }
          //   });
          // return axisValue;
          // },
        },
        grid: {
          left: "20%",
          right: "10%",
          //bottom: '30%',
          top: "20%",
          /* containLabel: true*/
        },
        // legend: {
        //   data: [
        //     {
        //       name: "用水量",
        //       textStyle: {
        //         color: "#ffffff",
        //       },
        //     },
        //   ],
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              lineStyle: {
                color: "#ffffff",
              },
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                color: "#ffffff",
              },
            },
            axisTick: {
              show: false,
            },
            //xdata
            data: xdata,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "用水量（m³）",
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#ffffff",
              },
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                color: "#ffffff",
              },
            },
            splitNumber: 8,
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#25337e"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "用水量",
            type: "bar",
            barCategoryGap: "40%",
            barMaxWidth: 40,
            // barWidth:35,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  { offset: 0, color: "#009AB0" },
                  { offset: 1, color: "#31EDFE" },
                ]),
              },
            },
            markPoint: {
              data: [{ type: "max", name: "最大值" }],
              animationDelay: 1000,
              animationDuration: 1000,
            },
            // data: ydata,
            data: ydata,
          },
        ],
      };
     
        // alert(2)
          const YslChart = echarts.init(document.getElementById("mychart"));
        // YslChart.clear();
        // YslChart.hideLoading();
        //  this_.$nextTick(function(){
        YslChart.setOption(YslChartoption);
        // this_.$emit('set-parent-width', "width")
        // this_.$parent.$refs.parentElement.offsetWidth = "500px";
        YslChart.resize();
      // })
      // alert(3)
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        //这里你得先获取父级的宽度 然后再resize
        //1
        //   $('.echarts').css({ 'width': $('#myTabContent').width() });
        // $(".echart").css({
        //   width: this_.$parent.$parent.$refs.parentWidth.width(),
        // });
        // YslChart.resize({
        //   width: 500,
        // });
      });
    },
    // getdom() {
    //   const myChart = document.getElementById("mychart");
    //   const firstChildWidth = myChart
    //     .querySelector(":first-child")
    //     .getBoundingClientRect().width;
    //     // console(firstChildWidth)
    // },
  },
};
</script>
