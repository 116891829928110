<template>
  <div class="chart">
    <div v-if="dataEmpty" class="error">
      <i class="el-icon-folder-opened" />
      <p>当前无数据</p>
    </div>
    <div ref="chart" class="chart" />
  </div>
</template>

<script>
import chart from '@/components/mixins/chart'
import { conversion } from '@/utils/common'

export default {
  name: 'Scatter',
  mixins: [chart],
  props: {
    data: {
      type: [Object, Array],
      default: () => []
    }
  },
  data() {
    return {
      chart: null,
      dataEmpty: false
    }
  },
  watch: {
    data(newVal) {
      this.setData(newVal)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    /**
     * 初始化图表
     */
    initChart() {
      this.chart = this.$echarts.init(this.$refs['chart'])
    },
    /**
     * 初始化数据
     */
    init() {
      // if (this.data && this.data.length === 0) {
      //   this.dataEmpty = true
      // }

      if (!this.dataEmpty) {
        this.initChart()
      }

      this.setData(this.data)
    },
    /**
     * 更新数据
     */
    setData(newData) {
      if (!this.chart || newData.length === 0) {
        return
      }
      this.chart.clear()
      const colors = [
        'rgba(54, 201, 127, 0.4)',
        'rgba(218, 202, 112, 0.4)',
        'rgba(10 ,171, 228, 0.4)'
      ]
      const aciveColors = [
        'rgba(54, 201, 127, 0.8)',
        'rgba(218, 202, 112, 0.8)',
        'rgba(10 ,171, 228, 0.8)'
      ]

      const tmpData = JSON.parse(JSON.stringify(newData)).sort((a, b) => b.persons - a.persons)

      const max = tmpData[0].persons
      const min = tmpData[tmpData.length - 1].persons

      if (tmpData.length > 0) {
        tmpData.forEach((item, index) => {
          const symbolSize = conversion(max, min, 60, 30, item.persons)
          const color = colors[index % 3]
          const activeColor = aciveColors[index % 3]
          if (index < 6) {
            item.symbolSize = symbolSize
            item.itemStyle = {
              color: activeColor
            }
            item.label = {
              show: true,
              fontSize: 12
            }
          } else {
            item.symbolSize = symbolSize
            item.itemStyle = {
              color
            }
            item.label = {
              show: false
            }
          }
        })
      }

      const series = {
        type: 'graph',
        layout: 'force',
        force: {
          repulsion: 40,
          gravity: 0.05
        },
        roam: false,
        label: {
          show: true
        },
        data: tmpData
      }

      this.chart.setOption({
        series
      })
    }
  }
}
</script>

<style lang="less" scoped>
.chart {
  width: 100%;
  height: 100%;
}

.error {
  padding: 16px 0;
  text-align: center;
}
</style>
