<template>
  <div class="chart">
    <div v-if="dataEmpty" class="error">
      <i class="el-icon-folder-opened" />
      <p>当前无数据</p>
    </div>
    <div ref="chart" class="chart" />
  </div>
</template>

<script>
import chart from '@/components/mixins/chart'

export default {
  name: 'LineChart',
  mixins: [chart],
  props: {
    data: {
      type: [Object, Array],
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chart: null,
      dataEmpty: false
    }
  },
  watch: {
    data(newVal) {
      this.setData(newVal)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    /**
     * 初始化图表
     */
    initChart() {
      console.log(this.$echarts);
      this.chart = this.$echarts.init(this.$refs['chart'])

      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0, 53, 97, 0.5)',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#003561'
            }
          },
          formatter: (params) => {
            const data = params[0]
            const str = `${data.axisValueLabel} <br />
                         人数: ${data.data} <br />
            `
            return str
          }
        },
        grid: {
          left: '6%',
          right: '6%',
          top: '38%',
          bottom: '8%',
          containLabel: true
        },
        color: ['#00F0BE'],
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: '#003561',
              width: 1
            }
          },
          axisLabel: {
            show: true,
            interval:0,
            textStyle: {
              color: '#F1F1F2',
              fontSize: 12,
              fontWeight: 400
            }
          }
        },
        yAxis: {
          nameTextStyle: {
            color: '#F1F1F2',
            fontSize: 12,
            fontWeight: 400
          },
          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#142d5c',
              type: 'dashed'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#F1F1F2',
              fontSize: 12,
              fontWeight: 400
            }
          }
        },
        series: []
      })
    },
    /**
     * 初始化数据
     */
    init() {
      if (this.data && this.data.yAxisDatas.length === 0) {
        this.dataEmpty = true
      }

      if (!this.dataEmpty) {
        this.initChart()
      }

      this.setData(this.data)
    },
    /**
     * 更新数据
     */
    setData(newData) {
      const series = []

      newData.yAxisDatas.forEach((item, i) => {
        series.push({
          name: item.name,
          data: item.data,
          type: 'line',
          symbol: 'circle',
          symbolSize: 8,
          itemStyle: {
            color: '#0DDEF9'
          },
          lineStyle: {
            color: '#0DDEF9',
            width: 3
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#0889ae'
              },
              {
                offset: 0.5,
                color: '#0889ae'
              },
              {
                offset: 1,
                color: 'transparent'
              }
            ])
          }
        })
      })

      this.chart.setOption({
        xAxis: {
          data: this.data.xAxisDatas
        },
        series
      })
    }
  }
}
</script>

<style lang="less" scoped>
.chart {
  width: 100%;
  height: 100%;
}

.error {
  padding: 16px 0;
  text-align: center;
}
</style>
