/**
 * 防抖函数
 * @param {Funciton} fn 函数
 * @param {Number} wait 等待时间
 * @param {immediate} Boolearn 是否立即执行
 */
export function debounce(fn, wait, immediate) {
  let timer
  return function() {
    if (timer) clearTimeout(timer)
    if (immediate) {
      // 如果已经执行过，不再执行
      var callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) {
        fn.apply(this, arguments)
      }
    } else {
      timer = setTimeout(() => {
        fn.apply(this, arguments)
      }, wait)
    }
  }
}

/**
 * 生成随机数函数
 * @param {Number} start 开始
 * @param {Number} end 结束
 * @param {Number} fixed 小数位数
 */
export function getRandom(start, end, fixed = 0) {
  const differ = end - start
  const random = Math.random()
  return (start + differ * random).toFixed(fixed)
}

/**
 * 区间换算
 * @param {Numebr}} max1 第一区间最大值
 * @param {Numebr} min1 第一区间最小值
 * @param {Numebr} max2 第二区间最大值
 * @param {Numebr} min2 第二区间最小值
 * @returns {Numebr} value 换算结果
 */
export function conversion(max1, min1, max2, min2, value) {
  if (max1 === min1) {
    return max2
  }
  const res = ((value - min1) / (max1 - min1)) * (max2 - min2) + min2
  return parseInt(res)
}

/**
 * 获得日期
 * @param {Number} num 回退
 * @param {String} str 拼接符
 * @returns {String} 获得日期
 */
export function getDay(num, str) {
  const today = new Date()
  const nowTime = today.getTime()
  const ms = 24 * 3600 * 1000 * num
  today.setTime(parseInt(nowTime + ms))
  const oYear = today.getFullYear()
  let oMoth = (today.getMonth() + 1).toString()
  if (oMoth.length <= 1) oMoth = '0' + oMoth
  let oDay = today.getDate().toString()
  if (oDay.length <= 1) oDay = '0' + oDay
  console.log(oYear + str + oMoth + str + oDay)
  return oYear + str + oMoth + str + oDay
}