<template>
  <div class="main">
    <div class="header">
      <span>智慧城市大数据平台</span>
    </div>
    <div id="screen" class="screen"></div>
    <big-left class="left"></big-left>
    <big-right class="right"></big-right>
    <div class="bottom">
      <div
          class="btn"
          v-for="(item, key) in address"
          :key="key"
          @click="fly(item.name)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import curves from "@/three/curve";
import { curves3 } from "@/three/curve";
import { createBuildingRoad } from "@/three/buildingAndRoad";
import { createSprite } from "@/three/sprite";
import address from "@/assets/mock/address.js";
import * as THREE from "three";
import bigLeft from "@/components/left";
import bigRight from "@/components/right";

let app,
    camera,
    scene,
    renderer,
    renderOrder = 0,
    controls,
    clock,
    cityModel,
    // 车轮数组
    carWheel = [],
    cavasHtmlGroup = new THREE.Group(),
    buildingGroup = new THREE.Group(),
    tubeGroup = new THREE.Group(),
    textureLoader = new THREE.TextureLoader(),
    skyTexture,
    bloomComposer,
    speed = 0.0005,
    s = 0,
    p = 1,
    range = 1000,
    progress = 0;

export default {
  name: "Home",
  components: {
    bigLeft,
    bigRight,
  },
  data() {
    return {
      // 小车移动状态
      isCarMove: false,
      address: address,
      isLoaded: false,
      loading: null,
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.initLight();
      skyTexture = app.loaderSky("texture/sky2/");
      bloomComposer = app.bloomComposer();
      // 点击精灵飞行
      app.initRaycaster((obj) => {
        if (obj.isSprite) {
          address.forEach((item) => {
            if (item.name === obj.name) {
              app.flyTo({
                position: item.cameraPosition,
                duration: 1500,
              });
            }
          });
        }
      });
      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(2, 44, -32);
      clock = new THREE.Clock();

      cityModel = await app.loaderGltfDracoModelOld("model/city/", "modelDraco",
          (percentage) => {
            // if(this.loading&&this.loading.visible)
            // this.loading.text = "资源正在加载中，请稍后"+"···"+percentage+"%";
          });

      let lineMaterial = new THREE.LineBasicMaterial({
        color: "#57d8ff",
        transparent: true,
        linewidth: 5,
        opacity: 1.0,
      });

      cityModel.traverse((model) => {
        if (model.isMesh) {
          let edges = new THREE.EdgesGeometry(model.geometry, 1);
          let obj = new THREE.LineSegments(edges, lineMaterial);
          buildingGroup.add(obj);
        }
      });
      buildingGroup.renderOrder = renderOrder++;
      scene.add(buildingGroup);

      // 添加平面
      let plane = app.loaderPlane("texture/plane.png");
      plane.position.set(0, -10, 0);
      plane.renderOrder = 2;
      scene.add(plane);

      // 红色线贴图
      let redLineTexture = textureLoader.load("texture/red_line.png");
      redLineTexture.wrapS = redLineTexture.wrapT = THREE.RepeatWrapping;
      redLineTexture.repeat.set(1, 1);
      redLineTexture.needsUpdate = true;

      // 绿色线贴图
      let greenLineTexture = textureLoader.load("texture/green_line.png");
      greenLineTexture.wrapS = greenLineTexture.wrapT = THREE.RepeatWrapping;
      greenLineTexture.repeat.set(1, 1);
      greenLineTexture.needsUpdate = true;

      let tubeRedMaterial = new THREE.MeshBasicMaterial({
        map: redLineTexture,
        side: THREE.BackSide,
        transparent: true,
      });

      let tubeGreenMaterial = new THREE.MeshBasicMaterial({
        map: greenLineTexture,
        side: THREE.BackSide,
        transparent: true,
      });

      // 创建道路
      curves.forEach((curve, index) => {
        let tube = app.loaderTube(
            curve,
            index % 2 === 0 ? tubeRedMaterial : tubeGreenMaterial
        );
        tubeGroup.add(tube);
      });

      curves3.forEach((curve, index) => {
        let tube = app.loaderTube(
            curve,
            index % 2 === 0 ? tubeRedMaterial : tubeGreenMaterial
        );
        tubeGroup.add(tube);
      });
      tubeGroup.renderOrder = renderOrder++;
      scene.add(tubeGroup);

      // 创建文本

      address.forEach((item) => {
        createSprite(cavasHtmlGroup, item.name, item.position);
      });
      cavasHtmlGroup.renderOrder = renderOrder++;
      scene.add(cavasHtmlGroup);

      // 创建扩散波
      let diffusion = app.loaderDiffusion("texture/test1.png");
      diffusion.position.set(
          -255.8586862085735,
          20.000000000000012,
          -54.70926350083916
      );
      diffusion.renderOrder = 100;
      scene.add(diffusion);

      // 加载粒子背景
      let points = app.laoderPartcle(1000, 200, 'texture/snow.png');
      points.position.set(0, 0, -127);
      scene.add(points);

      this.loading.close();

      camera.position.set(-41, 1021, 312);

      app.flyTo({
        position: [17, 162, 487],
        duration: 3000,
      });

      app.render(() => {
        controls.update(clock.getDelta());
        // renderer.render(scene, camera);
        bloomComposer.render();
        TWEEN.update();

        if (points) {
          let vertices = points.geometry.vertices;
          vertices.forEach((v) => {
            // 计算位置
            v.y = v.y - v.velocityY;
            v.x = v.x - v.velocityX;
            v.z = v.z - v.velocityZ;

            // 边界检查
            if (v.y <= -range / 2) v.y = range / 2;
            if (v.x <= -range / 2 || v.x >= range / 2) v.x = v.x * -1;
            if (v.z <= -range / 2 || v.z >= range / 2)
              v.velocityZ = v.velocityZ * -1;
          });
          points.geometry.verticesNeedUpdate = true;
        }

        if (redLineTexture) {
          redLineTexture.offset.x += 0.005;
        }

        if (greenLineTexture) {
          greenLineTexture.offset.x += 0.005;
        }

        s += 0.01;
        p -= 0.005;
        if (s > 2) {
          s = 0;
          p = 1;
        }
        diffusion.scale.set(1 + s, 1, 1 + s);
        diffusion.material[0].opacity = p;
      });
    },
    fly(name) {
      address.forEach((item) => {
        if (item.name === name) {
          app.flyTo({
            position: item.cameraPosition,
            duration: 1500,
          });
        }
      });
    },
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: "拼命加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 1)",
    });
    this.initZThree();
  },
};
</script>

<style lang='less' scoped>
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  .screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .left {
    width: 20%;
    height: 88%;
    position: absolute;
    top: 9%;
    left: 2%;
  }
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    color: #fff;
    line-height: 100px;
    background: url("./../assets/images/top.png") center bottom no-repeat;
    font-size: 40px;
    text-align: center;
    z-index: 2;
  }
  .right {
    width: 20%;
    height: 88%;
    position: absolute;
    top: 9%;
    right: 2%;
  }
  .bottom {
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      background: url("./../assets/images/bnt.png") 100% 100% no-repeat;
      width: 91px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      color: #fff;
      font-size: 16px;
      margin: 0 6px;
    }
  }
}
</style>
<style lang="less">
.sprite-canvas {
  position: absolute;
  width: 1024px;
  height: 1024px;
  font-size: 128px;
  top: 0;
  box-sizing: border-box;
  background-color: transparent;
  color: #fff;
  text-align: center;
  .sprite-layer {
    margin-top: 60%;
    background-color: blue;
    padding: 1% 2%;
  }
}
</style>
