<template>
  <div class="left animated slideInLeft">
    <box title="交通资产投入" class="box">
      <pie-chart title="交通资产投入" :data="pieData" />
    </box>
    <box title="交通影响因素" class="box">
      <line-chart v-if="lineData" :data="lineData" />
    </box>
    <box title="警力资源投入" class="box">
      <line-chart v-if="lineData" :data="lineData" />
    </box>
  </div>
</template>

<script>
import box from "@/components/box";
import lineChart from "@/components/chart/lineChart";
import pieChart from "@/components/chart/pieChart";

export default {
  name: "Left",
  props: {
    showChart: {
      type: Boolean,
      default: false
    }
  },
  components: {
    lineChart,
    pieChart,
    box
  },
  props: {},
  data() {
    return {
      pieData: [{
        name: '高速公路',
        value: 33
      }, {
        name: '汽车站点',
        value: 22
      }],
      lineData: null,
      line2Data: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getLineData();
      this.getLine2Data();
    },
    /**
     * 线图数据
     */
    getLineData() {
      this.lineData = {
        xAxisDatas: [
          "上班高峰",
          "下班高峰",
          "施工路段",
          "恶劣天气",
          "行人违法",
        ],
        yAxisDatas: [
          {
            name: "",
            data: [
              80,
              120,
              140,
              80,
              100,
            ]
          }
        ]
      };
    },
    getLine2Data() {
      this.lineData = {
        xAxisDatas: [
          "白云区",
          "南明区",
          "云岩区",
          "乌当区",
          "花溪区",
        ],
        yAxisDatas: [
          {
            name: "",
            data: [
              60,
              100,
              200,
              140,
              160,
            ]
          }
        ]
      };
    }
  }
};
</script>
<style lang='less' scoped>
.left {
  position: fixed;
  top: 80px;
  left: 1%;
  width: 20%;
  height: 90%;
  // background-color: rgba(0, 8, 49, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  .box {
    width: 100%;
    height: 30.7%;
  }
}
</style>
