<template>
  <div class="chart">
    <div v-if="dataEmpty" class="error">
      <i class="el-icon-folder-opened" />
      <p>当前无数据</p>
    </div>
    <div ref="chart" class="chart" />
  </div>
</template>

<script>
import chart from '@/components/mixins/chart'

export default {
  name: 'BarChart',
  mixins: [chart],
  props: {
    data: {
      type: [Object, Array],
      default: () => []
    }
  },
  data() {
    return {
      chart: null,
      dataEmpty: false
    }
  },
  watch: {
    data(newVal) {
      this.setData(newVal)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  beforeDestroy() {
    this.disposeChart()
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$refs['chart'])

      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '6%',
          right: '6%',
          top: '38%',
          bottom: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: '#003561',
              width: 1
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#F1F1F2',
              fontSize: 12,
              fontWeight: 400
            }
          }
        },
        yAxis: {
          nameTextStyle: {
            color: '#F1F1F2',
            fontSize: 12,
            fontWeight: 400
          },
          type: 'value',
          splitNumber:3,
          splitLine: {
            lineStyle: {
              color: '#142d5c',
              width: 1,
              type: 'dashed'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#F1F1F2',
              width: 1,
              fontSize: 12,
              fontWeight: 400
            }
          }
        },
        series: []
      })
    },
    // 请求图表数据
    init() {
      if (this.xAxisDatas && this.xAxisDatas.length === 0) {
        this.dataEmpty = true
      }

      if (!this.dataEmpty) {
        this.initChart()
      }

      this.setData(this.data)
    },
    // 更新数据
    setData(newData) {
      if (!this.chart || !newData.xAxisDatas || !newData.yAxisDatas) {
        return
      }
      const data = newData.yAxisDatas[0]
      const seriesData = [
        {
          name: data.name,
          type: 'bar',
          barWidth: 20,
          data: data.data,
          itemStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#167AD9' },
              { offset: 1, color: '#63FEFD' }
            ])
          },
          // label: {
          //   show: true,
          //   position: 'top',
          //   color: 'red',
          //   fontWeight: 400,
          //   distance: 10
          // }
        },
        {
          name: '',
          type: 'pictorialBar',
          symbolSize: [30, 10],
          symbolOffset: [0, 5],
          z: 10,
          // itemStyle: {
          //   normal: {
          //     color: '#63FEFD'
          //   }
          // },
          // data: data.data
        },
        {
          name: '',
          type: 'pictorialBar',
          symbolSize: [30, 10],
          symbolOffset: [0, -5],
          symbolPosition: 'end',
          // z: 10,
          // itemStyle: {
          //   normal: {
          //     color: '#167AD9'
          //   }
          // },
          // data: data.data
        }
      ]

      // 重新给chart赋值
      this.chart.setOption({
        xAxis: [
          {
            type: 'category',
            data: newData.xAxisDatas,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        series: seriesData
      })
    },
    // 销毁报表
    disposeChart() {
      if (!this.chart) {
        return
      }
      this.chart.dispose()
      this.chart = null
    }
  }
}
</script>

<style lang="less" scoped>
.chart {
  width: 100%;
  height: 100%;
}

.error {
  padding: 16px 0;
  text-align: center;
}
</style>
